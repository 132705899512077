import React from 'react'
import { Accordion, Button } from 'react-bootstrap'
import styles from './AccordionItext.module.scss'
import Itext from './Itext/Itext';
export default function AccordionItext(props) {
    const { itext } = props;
    //TODO: el accordion item en un componente llamado itext generico para tm y con la logica de toc
    //console.log(itext)
    return (
        <Accordion defaultActiveKey={itext.length - 1} className={`${styles.accordion}`}>
            {
                itext.map((item, index) => {
                    return (
                        <Itext item={item} index={index} key={index}/>
                    )
                })
            }
        </Accordion>
    )
}
