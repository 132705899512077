import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import styles from './SearchBar.module.scss';
import { useNavigate } from 'react-router-dom';

export default function SearchBar(props) {
    const { offcanvas } = props;
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate(); // Utiliza useNavigate para la navegación

    const handleInputChange = (e) => {
        //console.log(e.target.value);
        setSearchText(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const encodedSearchText = encodeURIComponent(searchText);
        const absoluteURL = `/search/?keywords=${encodedSearchText}&respp=10`;
        window.location.href = absoluteURL;
    };
    return (
        <Form onSubmit={handleSubmit} className={`${styles.search} d-flex`}>
            <InputGroup className={`${styles.inputGroup}`}>
                <InputGroup.Text id="basic-addon1" className={`${styles.icon}`}><Search /></InputGroup.Text>
                <Form.Control
                    type="search"
                    placeholder={`Search`}
                    className={`${styles.input} me-2`}
                    aria-label="Search"
                    value={searchText}
                    onChange={handleInputChange}
                />
            </InputGroup>
        </Form>
    )
}
