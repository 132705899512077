import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Search } from 'react-bootstrap-icons'
import styles from './SearchBar.module.scss'
export default function SearchBar(props) {
  const {placeholder} = props
  const [searchText, setSearchText] = useState('');

    const handleInputChange = (e) => {
        //console.log(e.target.value);
        setSearchText(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const encodedSearchText = encodeURIComponent(searchText);
        const absoluteURL = `/search/?keywords=${encodedSearchText}&respp=10`;
        window.location.href = absoluteURL;
    };
  return (
    <Form onSubmit={handleSubmit}  className={`d-flex ${styles.search}`}>
        <Form.Control
            type="search"
            placeholder={placeholder}
            className={`me-2 ${styles.input}`}
            aria-label={placeholder}
            onChange={handleInputChange}
        />
        <Button variant="secondary" className={`${styles.btn}`} type='submit'>
           <Search/> SEARCH
        </Button>
    </Form>
  )
}
