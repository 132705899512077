import React from 'react'
import Option from './Option/Option'
import styles from './SideBarWhatWeDo.module.scss'
export default function SideBarWhatWeDo() {
    return (
        <div className={`${styles.sideBar}`}>
            <Option title='Projects'
                
            nav={'/category/Aegis_Trust_Projects'}
            >
                The Genocide Archive of Rwanda conducts a range of specific archive, research and documentations projects. Click on the links below to learn more about our current projects.
                <ul>
                    <li>
                        Rwanda Peace Education Programme (RPEP)
                    </li>
                    <li>
                        Genocide Research and Reconciliation Programme
                    </li>
                    <li>
                        Mapping Genocide and Reconstruction
                    </li>
                    <li>
                        Victims' Database
                    </li>
                </ul>
            </Option>
            <Option 
            title='Reports'
            nav={'/reports'}
            >
                Follow the link below to access reports on the work of the Genocide Archive of Rwanda and Aegis Trust, including annual reports and those for specific projects.
            </Option>
        </div>
    )
}
