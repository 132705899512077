import React from 'react';
import Navbar from './Navbar/Navbar';

import styles from './BasicLayout.module.scss';
import Footer from './Footer/Footer';
import TopPart from 'modules/website/components/TopPart/TopPart';
import { useScrollRestoration } from 'common/hooks/useScrollRestoration';

export default function BasicLayout(props) {
	const { children, toppart, background } = props;
	const scrollRef = useScrollRestoration();

	return (
		<div className='d-flex flex-column vh-100 vw-100 overflow-auto' ref={scrollRef}>
			<a
				href='#main'
				className={`d-flex flex-row w-100 justify-content-center ${styles.skip_main_content}`}>
				Skip to main content
			</a>
			<Navbar />
			<main id='main' className='d-flex flex-column flex-grow-1 bg-black' style={{backgroundImage: `url(${background})`,backgroundSize:'cover'}}>
				{
					toppart && (
						<TopPart breadC={toppart.breadC} title={toppart.title} description={toppart.description} warning={toppart.warning}/>
					)
				}
				{children}
			</main>
			<Footer />
		</div>
	);
}
