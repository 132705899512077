import { useCallback, useEffect, useRef } from "react";

const KEY = "useScrollRestoration-store";

let scrolls;
let storageAvailable = true;
try {
  scrolls = JSON.parse(sessionStorage.getItem(KEY) ?? "{}");
} catch (e) {
  storageAvailable = false;
}

/**
 * Custom hook for restoring scroll position based on a unique key. The hook returns a callback function
 * that should be set on the JSX element's ref attribute to manage scroll restoration.
 *
 * @param {string} [key=window.location.href] - A unique key to identify the scroll position, defaults to current URL.
 * @param {number} [timeout=500] - A timeout after which the scroll will not be restored, defaults to 1/2 a second.
 * @returns {Function} A callback function to set as the `ref` on a scrollable JSX element.
 *
 * @example
 * const scrollRef = useScrollRestoration();
 * return <div ref={scrollRef}>Your Content Here</div>;
 */

export function useScrollRestoration(
  key = window.location.href,
  timeout = 1500
) {
  const updateTimer = useRef(0);
  const handler = useRef(noop);
  const cleanUp = useRef(noop);
  const connectRef = useCallback(connect, [key, timeout]);
  const tracked = useRef();
  useEffect(() => {
    if (tracked.current) {
      connectRef(tracked.current);
    }
    return disconnect;
  }, [connectRef]);
  return connectRef;

  function connect(ref) {
    disconnect();
    tracked.current = ref;
    if (ref) {
      ref.addEventListener("scroll", store);
      handler.current = () => {
        ref.removeEventListener("scroll", store);
      };

      const scrollInfo = scrolls[key];
      if (scrollInfo) {
        ref.scrollTop = scrollInfo.top;
        const resizeObserver = new ResizeObserver(() => {
          if (ref.scrollHeight > scrollInfo.top) {
            ref.scrollTop = scrollInfo.top;
          } else {
            ref.scrollTop = ref.scrollHeight;
          }
          cleanUp.current();
        });
        setTimeout(() => cleanUp.current(), timeout);

        resizeObserver.observe(ref);
        cleanUp.current = () => {
          resizeObserver.unobserve(ref);
          cleanUp.current = noop;
        };
      }
    }

    function store() {
      scrolls[key] = {
        top: ref.scrollTop,
      };
      clearTimeout(updateTimer.current);
      updateTimer.current = setTimeout(() => {
        if (storageAvailable) {
          scrolls = clearScroll(scrolls);
          sessionStorage.setItem(KEY, JSON.stringify(scrolls));
        }
      }, 50);
    }
  }

  function disconnect() {
    handler.current();
    cleanUp.current();
  }
}

/**
 * Do nothing
 */
function noop() {}

/**
 * This function clear the sesiong storage object if these is more than 30 entries
 */
function clearScroll(scrollObject) {
  const maxEntries = 20;
  const entries = Object.keys(scrollObject);
  if (entries.length < maxEntries) return scrollObject;

  // Delete the old ones
  const oldEntries = entries.slice(0, entries.length - maxEntries + 1);
  oldEntries.forEach((entries) => delete scrollObject[entries]);
  return scrollObject;
}
