import React from 'react';
/* import styles from './post_genocide.module.css' */
import styles from './post_genocide.module.css';
import TopPart from 'modules/website/components/TopPart/TopPart';
export default function PostGenocideReconstructionView() {
	return (
		<div className={`${styles['post-genocide-bg']}`}>
			<TopPart
				breadC={[
					{
						title: 'Collections',
						path: '/collections',
					},
					{
						title: 'Post-Genocide Reconstruction',
						path: '',
					},
				]}
				Unity_and_Reconciliation
				title='POST GENOCIDE RECONSTRUCTION'
				description='Here you will find information about Rwanda’s post-genocide reconstruction efforts. This section includes a wide range of topics, from justice and commemoration to peace building and the role of young people in the country’s reconstruction.'
			/>

			<div
				className={`${styles['post-item-list']} ${styles['col-md-5']}`}
			>
				<div
					className={`${styles['post-item-row']} ${styles['col-xs-12']}`}
				>
					<div
						className={`${styles['post-item']} ${styles['col-xs-6']}`}
					>
						<div>
							<div className={`${styles['post-item-image']}`}>
								<a
									href='/search/?keywords=kwibuka&respp=10'
									target='_blank'
									className={`${styles['post-link-a']}`}
								>
									<img
										alt=''
										src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Kwibuka%2013.JPG&amp;rsargs[]='
										style={{
											width: '245px',
											height: '158px',
										}}
									/>
								</a>
							</div>
							<div className={`${styles['post-item-title']}`}>
								Kwibuka
							</div>
							<div
								className={`${styles['post-item-description']}`}
							>
								Kwibuka means ‘to remember’ and describes the
								annual commemoration of the 1994 genocide
								against the Tutsi in Rwanda. Here you can learn
								about the remembrance events that take place
								over the 100 days of mourning from April to
								July.
							</div>
						</div>
					</div>
					<div
						className={`${styles['post-item']} ${styles['col-xs-6']}`}
					>
						<div>
							<div className={`${styles['post-item-image']}`}>
								<a
									href='/category/Unity_and_Reconciliation'
									className={`${styles['post-link-a']}`}
								>
									<img
										alt=''
										src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Kwibuka.png&amp;rsargs[]='
										style={{
											width: '245px',
											height: '145px',
										}}
									/>
								</a>
							</div>
							<div className={`${styles['post-item-title']}`}>
								Unity and Reconciliation
							</div>
							<div
								className={`${styles['post-item-description']}`}
							>
								<p className={`${styles['post-p']}`}>
									<span style={{ color: '#333333' }}>
										Here you will find information about the
										policies and programmes initiated by the
										Government of Rwanda to foster unity and
										reconciliation in the country,
										post-genocide.
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`${styles['post-item-row']} ${styles['col-xs-12']}`}
				>
					<div
						className={`${styles['post-item']} ${styles['col-xs-6']}`}
					>
						<div>
							<div className={`${styles['post-item-image']}`}>
								<a
									href='/Young_People_Peace'
									target='_blank'
									rel='noopener noreferrer'
									className={`${styles['post-link-a']}`}
								>
									<img
										alt=''
										src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Youngpeople.png&amp;rsargs[]='
										style={{
											width: '245px',
											height: '144px',
										}}
									/>
								</a>
							</div>{' '}
							<div className={`${styles['post-item-title']}`}>
								Young people and Peace Building
							</div>
							<div
								className={`${styles['post-item-description']}`}
							>
								Find out more about the youth led organisations
								in Rwanda and around the world, inspiring and
								empowering young people to be leaders in the
								fight against genocide.
							</div>
						</div>
					</div>
					<div
						className={`${styles['post-item']} ${styles['col-xs-6']}`}
					>
						<div>
							<div className={`${styles['post-item-image']}`}>
								<a
									className={`${styles['post-link-a']}`}
									href='/category/Trauma_and_Healing'
									target='_blank'
								>
									<img
										alt=''
										src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Unitynreconciliation.png&amp;rsargs[]='
										style={{
											width: '245px',
											height: '152px',
										}}
									/>
								</a>{' '}
							</div>
							<div className={`${styles['post-item-title']}`}>
								Trauma and Healing
							</div>
							<div
								className={`${styles['post-item-description']}`}
							>
								<p className={`${styles['post-p']}`}>
									<span style={{ color: '#333333' }}>
										Learn about the initiatives and
										organisations supporting survivors of
										the genocide to deal with grief, trauma,
										post-traumatic stress disorder and other
										challenges.
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className={`${styles['post-mapping-cont']} ${styles['col-md-7']} ${styles['col-xs-12']}`}
			>
				<div className={`${styles['post-mapping-title']}`}>
					<a
						href='/Interactive_Maps'
						target='_blank'
						rel='noopener noreferrer'
						className={`${styles['post-link-a']}`}
					>
						<strong>Mapping Reconstruction</strong>
					</a>
				</div>
				<div className={`${styles['post-columns-cont']}`}>
					<div
						className={`${styles['post-first-column']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
					>
						<div
							className={`${styles['post-mapping-img']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							<img
								alt=''
								src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Post-memorials.png&amp;rsargs[]='
							/>
						</div>
						<div
							className={`${styles['post-mapping-subtitle']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							Memorials
						</div>
						<div
							className={`${styles['post-mapping-subdesc']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							<p className={`${styles['post-p']}`}>
								Take an interactive tour of Rwanda&rsquo;s
								memorial sites and learn about their history by
								watching testimonies from survivors and the
								families of victims buried there.
							</p>
						</div>
						<div
							className={`${styles['post-mapping-subdesc']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							<a
								className={`${styles['btn']} ${styles['btn-red']} ${styles['post-link-a']}`}
								href='/Interactive_Maps'
								target='_blank'
							>
								SEE MAP
							</a>
						</div>
					</div>
					<div
						className={`${styles['post-second-column']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
					>
						<div
							className={`${styles['post-mapping-img']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							<img
								alt=''
								src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Post-unity.png&amp;rsargs[]='
							/>
						</div>
						<div
							className={`${styles['post-mapping-subtitle']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							Unity and Reconciliation
						</div>
						<div
							className={`${styles['post-mapping-subdesc']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							<p className={`${styles['post-p']}`}>
								Find out where Rwanda&rsquo;s unity and
								reconciliation programmes are taking place,
								their objectives and outcomes.
							</p>
						</div>
						<div
							className={`${styles['post-mapping-subdesc']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							<a
								className={`${styles['btn']} ${styles['btn-red']} ${styles['post-link-a']}`}
								href='/Interactive_Map_Unity_and_Reconciliation'
								target='_blank'
							>
								SEE MAP
							</a>
						</div>
					</div>
					<div
						className={`${styles['post-third-column']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
					>
						<div
							className={`${styles['post-mapping-img']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							<img
								alt=''
								src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Post-youth peace.png&amp;rsargs[]='
								height='3540'
								width='5478'
							/>
						</div>
						<div
							className={`${styles['post-mapping-subtitle']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							Young People and Peace Building
						</div>
						<div
							className={`${styles['post-mapping-subdesc']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							<p className={`${styles['post-p']}`}>
								Find more about the work being done by youth
								organisations around the country to build peace
								and foster development in Rwanda.
							</p>
						</div>
						<div
							className={`${styles['post-mapping-subdesc']} ${styles['col-md-12']} ${styles['col-xs-6']}`}
						>
							<a
								className={`${styles['btn']} ${styles['btn-red']} ${styles['post-link-a']}`}
								href='/Interactive_Map_Youth'
								target='_blank'
								rel='noopener noreferrer'
							>
								SEE MAP
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className={`${styles['post-mapping-footer']}`}>
				<div
					className={`${styles['post-mapping-footer-desc']} ${styles['col-md-3']}`}
				>
					<div className={`${styles['post-mapping-footer-title']}`}>
						COMMUNITY RENEWAL
					</div>
					<div>
						Here you will find a selection of Rwandan practices that
						were designed to tackle socio-economic problems based on
						Rwandan tradition, culture and values to help with the
						country's post-genocide reconstruction.
					</div>
					<a
						className={`${styles['btn']} ${styles['btn-yellow']} ${styles['post-link-a']}`}
						href='/category/Community_Renewal'
						target='_self'
						rel='noopener noreferrer'
					>
						LEARN MORE
					</a>
				</div>
				<div
					className={`${styles['post-footer-item-cont']} ${styles['col-md-9']}`}
				>
					<div
						className={`${styles['post-footer-row']} ${styles['col-md-6']} ${styles['col-xs-12']}`}
					>
						<div
							className={`${styles['post-footer-item']} ${styles['col-xs-6']}`}
						>
							<div className={`${styles['post-footer-img']}`}>
								<a
									className={`${styles['post-footer-img-link']} ${styles['post-gacaca']} ${styles['post-link-a']}`}
									href='/search/?keywords=gacaca&amp;submit.x=0&amp;submit.y=0&amp;sortby=sorttitle&amp;rows=20&respp=10'
									target='_blank'
									rel='noopener noreferrer'
								>
									Gacaca
								</a>
							</div>
							<div
								className={`${styles['post-footer-item-desc']}`}
							>
								<p className={`${styles['post-p']}`}>
									Gacaca is a traditional community court
									system that aims at restoring the social
									fabric of society. It provides a means for
									survivors to learn the truth about the death
									of their relatives and or perpetrators to
									confess their crimes and seek forgiveness.
								</p>
							</div>
						</div>
						<div
							className={`${styles['post-footer-item']} ${styles['col-xs-6']}`}
						>
							<div className={`${styles['post-footer-img']}`}>
								<a
									className={`${styles['post-footer-img-link']} ${styles['post-ndi']} ${styles['post-link-a']}`}
									href='/category/Ndi_Umunyarwanda'
									target='_blank'
									rel='noopener noreferrer'
								>
									<span style={{ fontSize: '20px' }}>
										Ndi Umunyarwanda
									</span>
								</a>
							</div>
							<div
								className={`${styles['post-footer-item-desc']}`}
							>
								<p className={`${styles['post-p']}`}>
									Ndi Umunyarwanda, means 'I am Rwandan', is a
									programme initiated to build a national
									identity based on trust and dignity. It aims
									to strengthen unity and reconciliation among
									Rwandans by providing a forum for people to
									talk about the causes and consequences of
									the genocide as well as what it means to be
									Rwandan.
								</p>
							</div>
						</div>
					</div>
					<div
						className={`${styles['post-footer-row']} ${styles['col-md-6']} ${styles['col-xs-12']}`}
					>
						<div
							className={`${styles['post-footer-item']} ${styles['col-xs-6']}`}
						>
							<div className={`${styles['post-footer-img']}`}>
								<a
									className={`${styles['post-footer-img-link']} ${styles['post-tig']} ${styles['post-link-a']}`}
									href='/RPEP&amp;gsearch=RPEP'
									target='_blank'
									rel='noopener noreferrer'
								>
									RPEP
								</a>
							</div>
							<div
								className={`${styles['post-footer-item-desc']}`}
							>
								<p className={`${styles['post-p']}`}>
									The Rwanda Peace Education Programme (RPEP)
									was established by the Aegis Trust, the
									Institute of Research and Dialogue for Peace
									(IRDP), Radio La Belavolencija HTF and the
									USC Shoah Foundation to promote peace
									education in Rwanda.
								</p>
							</div>
						</div>
						<div
							className={`${styles['post-footer-item']} ${styles['col-xs-6']}`}
						>
							<div className={`${styles['post-footer-img']}`}>
								<a
									className={`${styles['post-footer-img-link']} ${styles['post-ingando']} ${styles['post-link-a']}`}
									href='/search/?keywords=ingando&amp;submit.x=0&amp;submit.y=0&amp;sortby=sorttitle&amp;rows=20&respp=10'
									target='_blank'
									rel='noopener noreferrer'
								>
									Ingando
								</a>
							</div>
							<div
								className={`${styles['post-footer-item-desc']}`}
							>
								<p className={`${styles['post-p']}`}>
									Ingando is a civic education programme that
									has facilitated the reintegration of
									refugees and prisoners back into their
									communities. Today, Ingando aims to help
									Rwandans learn about their past and forge a
									vision for a united future.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div style={{ clear: 'both' }}>
					<input style={{ display: 'none' }} type='hidden' />
				</div>
			</div>
		</div>
	);
}
