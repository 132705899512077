import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { singout } from "services/redux/auth/authActions";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Logout.module.scss";

export default function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(singout());

    // Redirigir después de 5 segundos
    const timer = setTimeout(() => {
      window.location.href = '/';// Redirige a la página de inicio
    }, 3000); // 5000 milisegundos = 5 segundos

    // Limpiar el temporizador si el componente se desmonta antes de la redirección
    return () => clearTimeout(timer);
  }, [dispatch, navigate]);

  return (
    <>
      <CheckMarkSuccess />
      <div id="mainContent">
        <p className="text-center mt-3">
          <b><h2>You are now logged out.</h2></b>
        </p>
        <p className="text-center">
          You can continue to use Genocide Archive Rwanda anonymously, or you can <Link to="/login" className={`${styles.link}`}>log in again</Link> as the same or as a different user.
          Note that some pages may continue to be displayed as if you were still logged in, until you clear your browser cache.
        </p>
        <p id="mw-returnto" className="text-center">Return to <Link className={`${styles.link}`} to="/" title="Welcome to Genocide Archive Rwanda">Home Page</Link>.</p>
      </div>
    </>
  );
}