import React from 'react';
import Header from './Header/Header';
import './extra-style.scss';

export default function Article(props) {
	const { title, mediaSharing = false, children } = props;
	return (
		<div className={`d-flex w-100 flex-column`}>
			<Header title={title} mediaSharing={mediaSharing} />
			<section
				className={`d-flex flex-column justify-content-center`}>
				{children}
			</section>
		</div>
	);
}
