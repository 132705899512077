import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import styles from './SearchSquareForm.module.scss'
import { ChevronRight } from 'react-bootstrap-icons'
export default function SearchSquareForm(props) {
    const { title, description, bkcolor,btncolor, type } = props; 
    const [searchText, setSearchText] = useState('');

    const handleInputChange = (e) => {
        //console.log(e.target.value);
        setSearchText(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const encodedSearchText = encodeURIComponent(searchText);
        let absoluteURL = `/search/?keywords=${encodedSearchText}&respp=10`;
        if(type){
            absoluteURL += `&contenttype=${type}`;
        }
        window.location.href = absoluteURL;
    };
    return (
        <Form onSubmit={handleSubmit} className={`${styles.form}`} style={{ background: bkcolor }}>
            <div className={`${styles.title}`}>
                {title}
            </div>
            <div className={`${styles.description}`}>
                {description}
            </div>
            <Form.Group className={`${styles.formGroup} mb-3`} controlId="formBasicPassword">
                <Form.Control
                    type="input"
                    value={searchText}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Button variant="primary" type="submit" className={`${styles.btn}`} style={{ '--btncolor': `${btncolor}` }}>
                <ChevronRight className={`${styles.icon}`} />
                SEARCH
            </Button>
        </Form>
    )
}