import AccordionItext from "modules/glifos-rich-media/components/AccordionItext/AccordionItext";
import CreateSegment from "modules/glifos-rich-media/components/CreateSegment/CreateSegment";
import GLifosMetadata from "modules/glifos-rich-media/components/GLifosMetadata/GLifosMetadata";
import GlifosRichMediaPlayerIOS_test from "modules/glifos-rich-media/components/GlifosRichMediaPlayer/GlifosRichMediaIOS_test";
import GlifosRichMediaPlayer from "modules/glifos-rich-media/components/GlifosRichMediaPlayer/GlifosRichMediaPlayer";
import GlifosRichMediaProvider from "modules/glifos-rich-media/components/GlifosRichMediaProvider/GlifosRichMediaProvider";
import Alert from "modules/website/components/Alert/Alert";
import NotRegisteredAd from "modules/website/components/NotRegisteredAd/NotRegisteredAd";
import { useVideoInfoPage } from "modules/website/hooks/useVideoInfoPage";
import React, { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { PaintBucket } from "react-bootstrap-icons";
import { useLocation, useParams } from "react-router-dom";

//https://streaming.gar.rw/vod/mp4:KMC00065.mp4/manifest.mpd 
export default function GlifosRichMediaView(props) {
    const { setBreadC, title } = props;
    const location = useLocation();
    const [video, setVideo] = React.useState(null);
    const { alias, alias2 } = useParams();
    //const alias = location.pathname.split("/")[0];
    //const alias2 = location.pathname.split("/")[1];
    //console.log(alias);
    const { isLoading, isError, payload, error } = useVideoInfoPage({
        alias: `${alias}${alias2 ? "$$" + alias2 : ""}`,
        stylesheet: 'DefaultJSON',
    });

    useEffect(() => {
        if (payload?.video) {
            let vid = payload.video.replace('rtmp', 'https');

            // Crear un objeto URL para manipular fácilmente el URI
            //console.log(vid);
            vid = vid.replace('https://streaming.gar.rw ', 'https://streaming.gar.rw');
            let url = new URL(vid);

            // Quitar el puerto, si existe
            url.port = '';

            // Reemplazar espacios por '%20' (encodeURIComponent se encarga de esto)
            //let encodedPathname = encodeURIComponent(url.pathname).replace(/%2F/g, '/');
            // Reconstruir el URL con los componentes codificados
            let encodedUrl = `${url.protocol}//${url.hostname}${url.pathname}`;

            if (payload.os === "iOS") {
                encodedUrl += '/playlist.m3u8';
            } else {
                encodedUrl += '/manifest.mpd';
            }

            //console.log(encodedUrl);
            setVideo(encodedUrl);
        }
        /* if (payload?.video) {
            let vid = payload.video.replace('rtmp', 'https');
            //vid = vid.replace('http', 'https');
            vid += '/manifest.mpd';
            vid = vid.replace(' ', '%20');
            console.log(vid)
            setVideo(vid);
        } */
        if (payload) {
            //Add at the end of BreadC the title of the video
            const newBreadC = [...payload.breadC];
            newBreadC.push({ title: title });
            //console.log(newBreadC);
            setBreadC(newBreadC);
        }
    }, [payload]);

    return <GlifosRichMediaProvider>
        <Container>
            <Row>
                {/* {
                    payload?.video && (
                    )
                } */}
                {isLoading && <div>Loading...</div>}
                {isError && <Alert variant="danger">{error}</Alert>}
                {!isLoading && !isError && payload && (
                    <>
                        <Col md={12} lg={12} xl={8}>
                            {
                                video &&
                                /* <GlifosRichMediaPlayer src={video} />  */
                                <>
                                    <GlifosRichMediaPlayerIOS_test src={video} />
                                    <CreateSegment title={title}/>
                                </>
                            }
                        </Col>
                        <Col md={12} lg={12} xl={4}>
                            <AccordionItext itext={payload.itext} />
                        </Col>
                        <Col md={12}>
                            <GLifosMetadata metadata={payload.metadata} />
                        </Col>
                    </>
                )}
            </Row>
            {props.children}
        </Container>
    </GlifosRichMediaProvider>;
}
