import React from 'react';
import Heading from 'common/components/Typography/Heading/Heading';
import styles from './Header.module.scss';
import MediaSharing from '../MediaShering/MediaSharing';

export default function Header(props) {
	const { title, mediaSharing } = props;
	return (
		<header
			className={`d-flex flex-column gap-5 my-5 py-5 ${styles.heading}`}>
			<Heading variant={`H1`}>{title}</Heading>
			{mediaSharing && <MediaSharing title={title}/>}
		</header>
	);
}
