import React, { useState } from 'react';
import Navbar from '../BasicLayout/Navbar/Navbar';

import styles from './SideBarLayout.module.scss';
import Footer from '../BasicLayout/Footer/Footer';
import { Col, Row } from 'react-bootstrap';
import TopPart from 'modules/website/components/TopPart/TopPart';
import { useScrollRestoration } from 'common/hooks/useScrollRestoration';

export default function SideBarLayout(props) {
    const { children, rightBar, leftBar, title, description , breadC, background, warning,broCategory,backBtn} = props;
    const scrollRef = useScrollRestoration();

    return (
        <div className='d-flex flex-column vh-100 vw-100 overflow-auto' ref={scrollRef}>
            <a
                href='#main'
                className={`d-flex flex-row w-100 justify-content-center ${styles.skip_main_content}`}>
                Skip to main content
            </a>
            <Navbar />
            <main id='main' className={`d-flex flex-column flex-grow-1 bg-black ${styles.main}`} style={{backgroundImage: `url(${background})`,backgroundSize:'cover'}}>
                <Row className={`${styles.main_row}`}>
                    <TopPart breadC={breadC} title={title} description={description} warning={warning} broCategory={broCategory} backBtn={backBtn}/>
                    {
                        leftBar && (
                            <Col lg={3} md={12}>
                                <div className={`${styles.left_bar}`}>
                                    {leftBar}
                                </div>
                            </Col>
                        )
                    }

                    <Col lg={leftBar && !rightBar ? 9 : !leftBar && rightBar ? 9 : leftBar && rightBar ? 6 : !leftBar && !rightBar && 12} md={12}>
                        {children}
                    </Col>

                    {
                        rightBar && (
                            <Col lg={3} md={12}>
                                <div className={`${styles.right_bar}`}>
                                    {rightBar}
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </main>
            <Footer />
        </div>
    );
}
