import React, { useState } from 'react';
//import { Whatsapp, Envelope, Twitter, Facebook } from 'react-bootstrap-icons';

import styles from './MediaSharing.module.scss';
import facebook from 'assets/img/wx/fb-shr.png';
import share from 'assets/img/wx/share2.png';
import Twitter from 'assets/img/wx/twitter-shr.png';
import digg from 'assets/img/wx/digg.png';
import delicious from 'assets/img/wx/delicious.png';

export default function MediaSharing(props) {
	const { title } = props;
	const [link, setLink] = useState(window.location.href);
	const openPopup = (url) => {
        window.open(url, 'popup', 'width=800,height=600');
    };

	const SocialMediaOptions = [
        {
            title: 'Facebook',
            icon: facebook,
            link: 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(link) + '$&t=' + encodeURIComponent(title),
        },

        {
            title: 'Twitter',
            icon: Twitter,
            link: 'http://twitter.com/intent/tweet?text=' + encodeURIComponent(title) + '&url=' + encodeURIComponent(link),
        },
        {
            title: 'Digg',
            icon: digg,
            link: 'http://digg.com/submit?phase=2&url=' + encodeURIComponent(link) + '$&title=' + encodeURIComponent(title) + '$&bodytext=&topic=',
        },
        {
            title: 'Delicious',
            icon: delicious,
            link: 'http://del.icio.us/post?noui=&notes=&tags=&v=4&jump=close&url=' + encodeURIComponent(link) + '$&title=' + encodeURIComponent(title),
        },

    ]
	return (
		<div className='align-self-end'>
			<div className='d-flex flex-row flex-wrap gap-4'>
				{SocialMediaOptions.map((item, index) => {
					return (
						<div className={`${styles.btn}`} title={item.title} key={index}>
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();
									openPopup(item.link);
								}}
							>
								<img src={item.icon} className={`${styles.icon}`} />
							</a>
						</div>
					)
				})}
				{/* <div
					className={`d-flex flex-row align-items-center justify-content-center ${styles.social_media_box}`}
					onClick={() => console.log('Facebook')}>
					<Facebook size={25} />
				</div>
				<div
					className={`d-flex flex-row align-items-center justify-content-center ${styles.social_media_box}`}
					onClick={() => console.log('twitter')}>
					<Twitter size={25} />
				</div>
				<div
					className={`d-flex flex-row align-items-center justify-content-center ${styles.social_media_box}`}
					onClick={() => console.log('whatsapp')}>
					<Whatsapp size={25} />
				</div>
				<div
					className={`d-flex flex-row align-items-center justify-content-center ${styles.social_media_box}`}
					onClick={() => console.log('whatsapp')}>
					<Envelope size={25} />
				</div> */}
			</div>
		</div>
	);
}
