import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import styles from './RegisterView.module.scss'
import { countries } from 'modules/website/utilities/country'
import ApiURLs from 'services/BackendApi/BackendApiURLs'
import Alert from 'modules/website/components/Alert/Alert'
export default function RegisterView() {
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [username, setUsername] = React.useState('')
    const [country, setCountry] = React.useState(countries[0].iso)
    const [telephone, setTelephone] = React.useState(`(${countries[0].code})`)
    const [remember, setRemember] = React.useState(false)
    const [password, setPassword] = React.useState('')
    const [Repassword, setRepassword] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [bot, setBot] = React.useState('')
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);
    const handleChange = (e) => {
        const selectedCountry = e.target.value;
        setCountry(selectedCountry);
        const countryData = countries.find(c => c.iso === selectedCountry);
        if (countryData) {
            setTelephone(`(${countryData.code})`);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault(); // Previene el comportamiento predeterminado del formulario
        setError(null);
        setSuccess(null);
        /* console.log({
            'user_name': username,
            password,
            'user_real_name': firstName + ' ' + lastName,
            country,
            'user_telephone': telephone,
            'user_email': email,
            honey: bot,
        }) */
        if (password !== Repassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            const response = await fetch(ApiURLs.baseURL + '/v1/website/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'user_name': username,
                    password,
                    'user_real_name': firstName + ' ' + lastName,
                    country,
                    'user_telephone': telephone,
                    'user_email': email,
                    honey: bot,
                }),
            });

            if (!response.ok) {
                setError('Failed to register, please check your credentials and try again.');
                console.log(response)
                //throw new Error('Login failed');
            }

            const data = await response.json();
            //console.log(data); // Aquí puedes manejar la respuesta del backend
            if (data.success) {
                setSuccess('Account created successfully');
                window.location.href = '/login';
            } else {
                setError(data.message);
            }
            // Redirigir o hacer alguna acción con el token o información recibida
        } catch (err) {

            console.error('Error:', err);
            setError('Failed to register, please check your credentials and try again.');
        } /* finally {
            //setIsLoading(false);
        } */
    };

    /* const handleSubmit = async (e) => {
        e.preventDefault(); // Previene el comportamiento predeterminado del formulario

        setIsLoading(true);
        setError(null);

    }; */

    return (
        <Container>
            <Row className={`${styles.ad}`}>
                <Col md={9} sm={12} lg={6}>
                    Fields marked with <span className={`${styles.redFont}`}>*</span> are required.
                </Col>
            </Row>
            <Row className={`${styles.cont}`}>

                <Col md={9} sm={12} lg={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail" className={`${styles.fGroup}`}>
                            <Form.Label>First Name: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="text" className={`${styles.control}`}
                                onChange={(e) => {
                                    setFirstName(e.target.value)
                                }}
                                value={firstName}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className={`${styles.fGroup}`}>
                            <Form.Label>Last Name: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="text" className={`${styles.control}`}
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                                value={lastName}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className={`${styles.fGroup}`}>
                            <Form.Label>Username: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="text" className={`${styles.control}`}
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                }}
                                value={username}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className={`${styles.fGroup}`}>
                            <Form.Label>Country: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Select
                                onChange={handleChange}
                                className={`${styles.control}`}
                                required
                            >
                                {
                                    countries.map((country) => {
                                        return <option value={country.iso}>{country.name}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="formBasicTelephone" className={`${styles.fGroup}`}>
                            <Form.Label>Telephone:</Form.Label>
                            <Form.Control type="text" className={`${styles.control}`}
                                onChange={(e) => {
                                    setTelephone(e.target.value)
                                }}
                                value={telephone}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicPass" className={`${styles.fGroup}`}>
                            <Form.Label>Password:  <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="password" className={`${styles.control}`}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                value={password}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicRePass" className={`${styles.fGroup}`}>
                            <Form.Label>Retype password: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="password" className={`${styles.control}`}
                                onChange={(e) => {
                                    setRepassword(e.target.value)
                                }}
                                value={Repassword}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail" className={`${styles.fGroup}`}>
                            <Form.Label>E-mail: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="email" className={`${styles.control}`}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                value={email}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox" className={`${styles.fGroup}`}>
                            <Form.Check type="checkbox" label="Remember my login on this computer"
                                onChange={(e) => {
                                    setRemember(e.target.value)
                                }}
                                value={remember}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBot" className={`${styles.fGroup}`}>
                            <Form.Control type="text"
                                style={{
                                    display: 'none'
                                }}
                                onChange={(e) => {
                                    setBot(e.target.value)
                                }}
                                value={bot}
                            />
                        </Form.Group>
                        {error &&
                            <div className='mt-5'>
                                <Alert variant='danger' className={`${styles.error}`}>{error}</Alert>
                            </div>
                        }
                        {success &&
                            <div className='mt-5'>
                                <Alert variant='success' className={`${styles.error}`}>{success}</Alert>
                            </div>
                        }
                        <div>
                            <Button variant="primary" type="submit"
                                className={`${styles.btn}`}
                            >
                                Create account
                            </Button>
                            {/* <Button variant="primary"
                                className={`${styles.btn}`}
                                style={{ marginLeft: '10px' }}
                            >
                                Forgotten Password?
                            </Button> */}

                        </div>
                    </Form>
                </Col>
            </Row>
            <Row>

            </Row>
        </Container>
    )
}