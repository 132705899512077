import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import { Link } from "react-router-dom";
import styles from "./UpdatePassSuccess.module.scss";

export default function UpdatePassSuccess() {
    const navigate = useNavigate(); // Hook para la navegación

    useEffect(() => {
        // Temporizador de 3 segundos antes de redirigir
        const timer = setTimeout(() => {
            navigate("/"); // Redirigir a la página de inicio o la ruta que desees
        }, 3000);

        // Limpiar el temporizador si el componente se desmonta antes de los 3 segundos
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
            <CheckMarkSuccess />
            <div id="mainContent">
                <p className="text-center mt-3">
                    <b><h2>You updated your password correctly</h2></b>
                </p>
                <p className="text-center">
                    Next time you log in with your new password.
                </p>
                <p id="mw-returnto" className="text-center">
                    Return to <Link className={`${styles.link}`} to="/" title="Welcome to Genocide Archive Rwanda">Home Page</Link>.
                </p>
            </div>
        </>
    );
}