import React, { useState } from 'react'
import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import styles from './LoginView.module.scss'
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import Alert from 'modules/website/components/Alert/Alert';
import { useDispatch } from "react-redux";
import { signin } from "services/redux/auth/authActions";
import FormRequiredValueWarning from "modules/website/components/FormRequiredValueWarning/FormRequiredValueWarning";
import FormRequiredValueIcon from "modules/website/components/FormRequiredValueIcon/FormRequiredValueIcon";
import { useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

export default function LoginView() {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [bot, setBot] = React.useState('');
    const [error, setError] = React.useState(null); // Estado para manejar errores
    const [isLoading, setIsLoading] = React.useState(false); // Estado para manejar el estado de carga
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        rememberMe: false,
        honey: bot
    });
    const [viewPassword, setViewPassword] = useState(false);

    const onInputChange = (e) => {
        let newErrors = { formErrors };
        let newFormData = { ...formData };
        newFormData[e.target.name] = e.target.value;
        if (newErrors.hasOwnProperty(e.target.name)) delete newErrors[e.target.name];
        setFormErrors(newErrors);
        setFormData(newFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let newErrors = {};
        setFormErrors(newErrors);
        setError(null);
        if (formData.username.trim() === "") newErrors["username"] = true;
        if (formData.password.trim() === "") newErrors["password"] = true;
        setFormErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const formMessage = await dispatch(signin(formData));
            //console.log(formMessage);
            setError(formMessage);
            setFormErrors({ __form_general_error: formMessage });
        }
    };

    if (auth?.refresh_token_expires_on && Object.keys(formErrors).length === 0) {
        return <Navigate to="/" replace />;
    }

    const changeViewPassword = () => {
        setViewPassword(!viewPassword);
    }
    /* const handleSubmit = async (e) => {
        e.preventDefault(); // Previene el comportamiento predeterminado del formulario

        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(ApiURLs.baseURL + '/v1/website/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'user_name': username,
                    password,
                    honey: bot,
                }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();
            console.log(data); // Aquí puedes manejar la respuesta del backend
            if (data.success) {
                window.location.href = '/dashboard';
            } else {
                setError(data.message);
            }
            // Redirigir o hacer alguna acción con el token o información recibida
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to login, please check your credentials and try again.');
        } finally {
            setIsLoading(false);
        }
    }; */

    return (
        <Container>
            <Row className={`${styles.cont}`}>
                <Col md={9} sm={12} lg={6}>
                    {/* <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail" className={`${styles.fGroup}`}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${styles.control}`}
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" className={`${styles.fGroup}`}>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                className={`${styles.control}`}
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicCheckbox" className={`${styles.fGroup}`}>
                            <Form.Check type="checkbox" label="Remember my login on this computer" />
                        </Form.Group>

                        <Form.Group controlId="formBot" className={`${styles.fGroup}`}>
                            <Form.Control
                                type="text"
                                style={{ display: 'none' }}
                                onChange={(e) => setBot(e.target.value)}
                                value={bot}
                            />
                        </Form.Group>

                        <div>
                            <Button
                                variant="primary"
                                type="submit"
                                className={`${styles.btn}`}
                                disabled={isLoading} // Deshabilitar el botón si está cargando
                            >
                                {isLoading ? 'Loading...' : 'SUBMIT'}
                            </Button>
                            <Button
                                variant="primary"
                                className={`${styles.btn}`}
                                style={{ marginLeft: '10px' }}
                            >
                                Forgotten Password?
                            </Button>
                        </div>
                    </Form> */}
                    <Form onSubmit={handleSubmit}>
                        <FloatingLabel label="Username" style={{ color: 'black' }}>
                            <Form.Control type="text" placeholder="Usuario" name="username" onChange={onInputChange} autoFocus className={`${styles.fGroup}`} />
                        </FloatingLabel>
                        {formErrors.username && <FormRequiredValueWarning />}
                        <FloatingLabel label="Password" className="d-flex mt-5" style={{ color: 'black' }}>
                            <Form.Control type={viewPassword ? "text" : "password"} placeholder="Password" name="password" onChange={onInputChange} id="passwordControl" className={`${styles.fGroup}`} />
                            {formData?.password?.trim() !== "" &&
                                (
                                    <div style={{ margin: "auto", fontSize: '20px' }}>
                                        {viewPassword ?
                                            <FaEyeSlash onClick={changeViewPassword} role="button" id="eyeBtn" style={{ color: 'black' }} />
                                            :
                                            <FaEye onClick={changeViewPassword} role="button" id="eyeBtn" style={{ color: 'black' }} />
                                        }
                                    </div>
                                )
                            }
                        </FloatingLabel>
                        {formErrors.password && <FormRequiredValueWarning />}
                        {/* <div className="px-1 mb-3">
                            <Form.Text muted>
                                <FormRequiredValueIcon /> Escriba la contraseña asignada a su usuario
                            </Form.Text>
                        </div> */}
                        <Form.Group controlId="formBot" className={`${styles.fGroup}`}>
                            <Form.Control
                                type="text"
                                style={{ display: 'none' }}
                                onChange={(e) => setBot(e.target.value)}
                                value={bot}
                            />
                        </Form.Group>
                        <div className="mt-5">
                            <Button
                                variant="primary"
                                type="submit"
                                className={`${styles.btn}`}
                                disabled={isLoading} // Deshabilitar el botón si está cargando
                            >
                                {isLoading ? 'Loading...' : 'SUBMIT'}
                            </Button>
                            <Link to="/forget_password">
                                <Button
                                    variant="primary"
                                    className={`${styles.btn}`}
                                    style={{ marginLeft: '10px' }}

                                >
                                    Forgotten Password?
                                </Button>
                            </Link>
                        </div>
                    </Form>

                    {error &&
                        <div className='mt-5'>
                            <Alert variant='danger' className={`${styles.error}`}>{error}</Alert>
                        </div>
                    } {/* Mostrar error si hay */}

                    <div className={`${styles.other}`}>
                        <div>
                            <p>Don't have an account? <Link to="/register">Create an account</Link>.</p>
                        </div>
                        <div>
                            <p>You must have cookies enabled to log in to Genocide Archive Rwanda.</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row></Row>
        </Container>
    );
}
