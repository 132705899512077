import React from 'react';
import Navbar from '../BasicLayout/Navbar/Navbar';

import styles from './CenteredLayout.module.scss';
import Footer from '../BasicLayout/Footer/Footer';
import TopPart from 'modules/website/components/TopPart/TopPart';
import { useScrollRestoration } from 'common/hooks/useScrollRestoration';

export default function CenteredLayout(props) {
	const { children, toppart } = props;
	const scrollRef = useScrollRestoration();

	return (
		<div className='d-flex flex-column vh-100 vw-100 overflow-auto' ref={scrollRef}>
			<a
				href='#main'
				className={`d-flex flex-row w-100 justify-content-center ${styles.skip_main_content}`}>
				Skip to main content
			</a>
			<Navbar />
			<main id='main' className='d-flex flex-column flex-grow-1 bg-black align-items-center'>
				{
					toppart && (
						<TopPart breadC={toppart.breadC} title={toppart.title} description={toppart.description} warning={toppart.warning}/>
					)
				}
				{children}
			</main>
			<Footer />
		</div>
	);
}
