//import LoginForm from 'modules/user/components/LoginForm/LoginForm';
//import ErrorMark from 'common/components/AnimatedIcons/ErrorMark/ErrorMark';
//import LogoutForm from 'modules/user/components/LogoutForm/LogoutForm';
//import ProtectedRoute from 'common/routes/PrivateRoute/PrivateRoute';

//LAYOUTS
import BasicLayout from 'common/layouts/BasicLayout/BasicLayout';
import CenteredLayout from 'common/layouts/CenteredLayout/CenteredLayout';
import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';

//VIEWS
import AboutUsView from 'modules/website/views/AboutUsView/AboutUsView';
import CollectionsView from 'modules/website/views/CollectionsView/CollectionsView';
import ContactUsView from 'modules/website/views/ContactUsView/ContactUsView';
import HomeView from 'modules/website/views/HomeView/HomeView';
import NotFoundView from 'modules/website/views/NotFoundView/NotFoundView';
import ResearchView from 'modules/website/views/ResearchView/ResearchView';
import NewView from 'modules/website/views/NewView/NewView';
import NewsView from 'modules/website/views/NewsView/NewsView';
import InfromationView from 'modules/website/views/InfromationView/InfromationView';
import ResearchInfoView from 'modules/website/views/ResearchInfoView/ResearchInfoView';
import SimpleAboutUsInfoView from 'modules/website/views/SimpleAboutUsInfoView/SimpleAboutUsInfoView';
import VictimsDatabaseView from 'modules/website/views/VictimsDatabaseView/VictimsDatabaseView';
import AboutUsInfoView from 'modules/website/views/AboutUsInfoView/AboutUsInfoView';
import WhatWeDoView from 'modules/website/views/WhatWeDoView/WhatWeDoView';
import PhotographsView from 'modules/website/views/PhotographsView/PhotographsView';

//COMPONENTS
import SideBarContactUs from 'modules/website/components/ContactUs/SideBarContactUs/SideBarContactUs';
import SidebarNew from 'modules/website/components/New/SideBarNew/SidebarNew';
import SideBarAboutUsInfo from 'modules/website/components/AboutUs/SideBarAboutUsInfo/SideBarAboutUsInfo';
import WhatWeDoTitle from 'modules/website/components/WhatWeDo/WhatWeDoTitle/WhatWeDoTitle';
import SideBarWhatWeDo from 'modules/website/components/WhatWeDo/SideBarWhatWeDo/SideBarWhatWeDo';
import SideBarVideo from 'modules/glifos-rich-media/components/SideBarVideo/SideBarVideo';
import SideBarDocument from 'modules/glifos-rich-media/components/SideBarDocument/SideBarDocument';
import GlifosRichMediaView from 'modules/website/views/GlifosRichMediaView/GlifosRichMediaView';
import GlifosDigitalArchiveView from 'modules/website/views/GlifosDigitalArchiveView/GlifosDigitalArchiveView';
import AliasNodeView from 'modules/website/views/AliasNodeView/AliasNodeView';
import React, { Suspense } from 'react';
import VideosView from 'modules/website/views/VideosView/VideosView';
import DocumentsView from 'modules/website/views/DocumentsView/DocumentsView';
import JobOpportunitiesView from 'modules/website/views/JobOpportunitiesView/JobOpportunitiesView';
import AudioView from 'modules/website/views/AudiosView/AudioView';
import SideBarAudio from 'modules/glifos-rich-media/components/SideBarAudio/SideBarAudio';

//BACKGROUNDS
import ContactUsBackground from 'assets/img/ContactUs/contactusbg.png';
import WhatWeDoBackground from 'assets/img/WhatWeDo/WhatWeDoBg.png';
import VideoBG from 'assets/img/Backgrounds/VideoBG.png';
import PhotoBG from 'assets/img/Backgrounds/PhotoBG.png';
import AudioBG from 'assets/img/Backgrounds/AudioBG.png';
import DocumentBG from 'assets/img/Backgrounds/DocumentBG.png';

//OTHERS
import {
	AboutUsInfoTop,
	AudioTop,
	ContactUsTop,
	DocumentsTop,
	FeaturedTop,
	GlifosDigitalArchiveTop,
	GlifosRichMediaTop,
	JoBopTop,
	NewTop,
	PhotoTop,
	PressRoomTop,
	TestimoniesTop,
	VideosTop,
	WhatWeDoTop,
} from 'routes/TopPart';
import ReportsView from 'modules/website/views/ReportsView/ReportsView';
import TestimoniesView from 'modules/website/views/TestimoniesView/TestimoniesView';
import SearchSquareForm from 'modules/glifos-rich-media/components/SearchSquareForm/SearchSquareForm';
import BrowseVideo from 'modules/glifos-rich-media/components/SideBarDocument/BrowseVideo/BrowseVideo';
import CategoryView from 'modules/website/views/CategoryView/CategoryView';
import PostGenocideReconstructionView from 'modules/website/views/PostGenocideReconstructionView/PostGenocideReconstructionView';
import BrowseSquare from 'modules/website/components/BrowseSquare/BrowseSquare';
import InteractiveMapView from 'modules/website/views/InteractiveMapView/InteractiveMapView';
import OurPartnersView from 'modules/website/views/OurPartnersView/OurPartnersView';
import InteractiveMapYouthView from 'modules/website/views/InteractiveMapYouthView/InteractiveMapYouthView';
import YoungPeoplePeaceView from 'modules/website/views/YoungPeoplePeaceView/YoungPeoplePeaceView';
import FeaturedView from 'modules/website/views/FeaturedView/FeaturedView';
import PressRoomView from 'modules/website/views/PressRoomView/PressRoomView';
import LoginView from 'modules/website/views/LoginView/LoginView';
import RegisterView from 'modules/website/views/RegisterView/RegisterView';
import Login from 'modules/website/views/Login/Login';
import Logout from 'modules/website/views/Logout/Logout';
import ForgetPasswordView from 'modules/website/views/ForgetPasswordView/ForgetPasswordView';
import ReplacePasswordView from 'modules/website/views/ReplacePasswordView/ReplacePasswordView';
import UpdatePassSuccess from 'modules/website/views/UpdatePassSuccessView/UpdatePassSuccess';
import RestorePasswordView from 'modules/website/views/RestorePasswordView/RestorePasswordView';

//import { Navigate } from 'react-router-dom';
//import apiService from 'services/BackendApi/BackendApi';
/* 
	NOTA:
	En Element debe ir el Layout y el Layout debe tener como props.children la el view que
	se va usar? 
*/

const DefaultRoutes = [
	{
		path: '/',
		element: (
			<BasicLayout>
				<HomeView />
			</BasicLayout>
		),
	},
	{
		path: '/about_us',
		element: (
			<BasicLayout>
				<AboutUsView />
			</BasicLayout>
		),
	},
	{
		path: '/contact_us',
		element: (
			<SideBarLayout
				title={ContactUsTop['title']}
				description={ContactUsTop['description']}
				breadC={ContactUsTop['breadC']}
				leftBar={<SideBarContactUs />}
				background={ContactUsBackground}
			>
				<ContactUsView />
			</SideBarLayout>
		),
	},
	{
		path: '/news',
		element: (
			<BasicLayout>
				<NewsView />
			</BasicLayout>
		),
	},
	{
		path: '/collections',
		element: (
			<BasicLayout>
				<CollectionsView />
			</BasicLayout>
		),
	},
	{
		path: '/research',
		element: (
			<BasicLayout>
				<ResearchView />
			</BasicLayout>
		),
	},
	{
		path: '/news/:id',
		element: (
			/* <SideBarLayout
				title={NewTop['title']}
				rightBar={<SidebarNew/>}
				breadC={NewTop['breadC']}
			> */
			<NewView />
			/* </SideBarLayout> */
		),
	},
	{
		path: '/research/:alias',
		element: (
			<BasicLayout>
				<ResearchInfoView />
			</BasicLayout>
		),
	},
	/* {
		path: '/about_us/press_room/:alias',
		element: (
			<BasicLayout>
				<SimpleAboutUsInfoView />
			</BasicLayout>
		),
	}, */
	{
		path: '/about_us/:alias',
		element: (
			<SideBarLayout
				title={AboutUsInfoTop['title']}
				leftBar={<SideBarAboutUsInfo />}
			>
				<AboutUsInfoView />
			</SideBarLayout>
		),
	},
	{
		path: '/about_us/what_we_do/:alias',
		element: (
			<SideBarLayout
				title={AboutUsInfoTop['title']}
				leftBar={<SideBarAboutUsInfo />}
			>
				<AboutUsInfoView />
			</SideBarLayout>
		),
	},
	{
		path: '/Victims_Database',
		element: (
			<BasicLayout>
				<VictimsDatabaseView />
			</BasicLayout>
		),
	},
	{
		path: '/what_we_do',
		element: (
			<SideBarLayout
				title={WhatWeDoTop['title']}
				breadC={WhatWeDoTop['breadC']}
				background={WhatWeDoBackground}
				rightBar={<SideBarWhatWeDo />}
			>
				<WhatWeDoView />
			</SideBarLayout>
		),
	},
	/* {
		path: '/--rich-media-test',
		element: (
			<SideBarLayout
				title={GlifosRichMediaTop['title']}
				breadC={GlifosRichMediaTop['breadC']}
				description={GlifosRichMediaTop['description']}
				leftBar={<SideBarVideo />}
			>
				<GlifosRichMediaView />
			</SideBarLayout>
		),
	}, */
	/* {
		path: '/La_France_N%27est_Nullement_Impliquee_Dans_Le_Genocide_Au_Rwanda',
		element: (
			<SideBarLayout
				title={GlifosDigitalArchiveTop['title']}
				breadC={GlifosDigitalArchiveTop['breadC']}
				leftBar={<SideBarDocument />}
			>
				<GlifosDigitalArchiveView />
			</SideBarLayout>
		),
	}, */
	{
		path: '/:alias',
		element: <AliasNodeView />,
	},
	{
		path: '/:alias/:alias2',
		element: <AliasNodeView />,
	},
	{
		path: '/category/videos',
		element: (
			<SideBarLayout
				title={VideosTop['title']}
				breadC={VideosTop['breadC']}
				description={VideosTop['description']}
				leftBar={<SideBarVideo />}
				warning={VideosTop['warning']}
				background={VideoBG}
			>
				<VideosView />
			</SideBarLayout>
		),
	},
	{
		path: '/category/Testimonies',
		element: (
			<SideBarLayout
				title={TestimoniesTop['title']}
				breadC={TestimoniesTop['breadC']}
				description={TestimoniesTop['description']}
				leftBar={
					<div>
						<SearchSquareForm
							title='Search Testimonies '
							description='Browse through this collection or use our advanced search to look for particular items of interest. Provide as many details such as dates, titles and key words for better results.'
							bkcolor='#bc1000'
							btncolor='#ff9200'
						/>
						<BrowseSquare
							title='Browse Testimonies'
							options={[
								{
									title: 'Places',
									link: '/category/Places',
								},
								{
									title: 'Topics',
									link: '/category/Topics',
								},
								{
									title: 'Keywords',
									link: '/category/Keywords',
								},
								{
									title: 'Key Events',
									link: '/category/Key_Events',
								},
								{
									title: 'People',
									link: '/category/People',
								},
								{
									title: 'Country Time Periods',
									link: '/category/Country_Time_Periods',
								},
							]}
						/>
					</div>
				}
				warning={TestimoniesTop['warning']}
				background={TestimoniesTop}
			>
				<TestimoniesView />
			</SideBarLayout>
		),
	},
	{
		path: '/category/documents',
		element: (
			<SideBarLayout
				title={DocumentsTop['title']}
				breadC={DocumentsTop['breadC']}
				description={DocumentsTop['description']}
				leftBar={<SideBarDocument />}
				warning={DocumentsTop['warning']}
				background={DocumentBG}
			>
				<DocumentsView />
			</SideBarLayout>
		),
	},
	{
		path: '/category/audio',
		element: (
			<SideBarLayout
				title={AudioTop['title']}
				breadC={AudioTop['breadC']}
				description={AudioTop['description']}
				leftBar={<SideBarAudio />}
				warning={AudioTop['warning']}
				background={AudioBG}
			>
				<AudioView />
			</SideBarLayout>
		),
	},
	{
		path: '/category/photographs',
		element: (
			<BasicLayout toppart={PhotoTop} background={PhotoBG}>
				<PhotographsView />
			</BasicLayout>
		),
	},
	{
		path: '/category/job_opportunities',
		element: (
			<BasicLayout toppart={JoBopTop}>
				<JobOpportunitiesView />
			</BasicLayout>
		),
	},
	{
		path: '/category/:category',
		element: <CategoryView />,
	},
	{
		path: '/reports',
		element: (
			<SideBarLayout title={'REPORTS'} leftBar={<SideBarAboutUsInfo />}>
				<ReportsView />
			</SideBarLayout>
		),
	},
	{
		path: '/Post_Genocide',
		element: (
			<BasicLayout>
				<PostGenocideReconstructionView />
			</BasicLayout>
		),
	},
	{
		path: '/Interactive_Maps',
		element: (
			<BasicLayout>
				<InteractiveMapView />
			</BasicLayout>
		),
	},
	{
		path: '/Interactive_Map_Youth',
		element: (
			<BasicLayout>
				<InteractiveMapYouthView />
			</BasicLayout>
		),
	},
	{
		path: '/Interactive_Map_Unity_and_Reconciliation',
		element: (
			<BasicLayout>
				<InteractiveMapYouthView />
			</BasicLayout>
		),
	},
	{
		path: '/Young_People_Peace',
		element: (
			<BasicLayout>
				<YoungPeoplePeaceView />
			</BasicLayout>
		),
	},
	{
		path: '/Our_Partners',
		element: (
			<BasicLayout>
				<OurPartnersView />
			</BasicLayout>
		),
	},
	{
		path: '/Featured',
		element: (
			<SideBarLayout
				title={FeaturedTop['title']}
				breadC={FeaturedTop['breadC']}
				description={FeaturedTop['description']}
			>
				<FeaturedView/>
			</SideBarLayout>
		),
	},
	{
		path: '/Press_room',
		element: (
			<SideBarLayout
				title={PressRoomTop['title']}
				breadC={PressRoomTop['breadC']}
				leftBar={
					<div>
						<SearchSquareForm
							title='Search Press Room'
							description='Browse through this collection or use our advanced search to look for particular items of interest. Provide as many details such as dates, titles and key words for better results.'
							bkcolor='#484848'
							btncolor='#ff9200'
						/>
						<BrowseSquare
							title='Browse Testimonies'
							options={[
								{
									title: 'News',
									link: '/News',
								},
								{
									title: 'Blog',
									link: '/blog',
								},
								{
									title: 'Gallery',
									link: 'https://www.flickr.com/photos/128827388@N06/',
								},
								{
									title: 'Key Events',
									link: '/category/Newsletters',
								},
								{
									title: 'People',
									link: '/Newsletters',
								}
							]}
						/>
					</div>
				}
			>
				<PressRoomView/>
			</SideBarLayout>
		),
	},
	{
		path: '/login',
		element: (
			<SideBarLayout
			title='Log in'>
				<LoginView />
			</SideBarLayout>
		),
	},
	{
		path: '/logout',
		element: (
			<SideBarLayout>
				<Logout />
			</SideBarLayout>
		),
	},
	{
		path: '/register',
		element: (
			<SideBarLayout
			title='Create account'>
				<RegisterView />
			</SideBarLayout>
		),
	},
	{
		path: '/forget_password',
		element: (
			<SideBarLayout
			title='Forgotten password'>
				<ForgetPasswordView />
			</SideBarLayout>
		),
	},
	{
		path: '/update_password',
		element: (
			<SideBarLayout
			title='Update password'>
				<ReplacePasswordView />
			</SideBarLayout>
		),
	},
	{
		path: '/update_password_success',
		element: (
			<SideBarLayout
			title='Update password'>
				<UpdatePassSuccess />
			</SideBarLayout>
		),
	},
	{
		path: '/restore/:token',
		element: (
			<SideBarLayout
			title='Restore password'>
				<RestorePasswordView />
			</SideBarLayout>
		),
	},
	/* {
		path: '/Terms_and_Conditions',
		element: (
			<BasicLayout>
				<TermsAndConditionsView />
			</BasicLayout>
		),
	},
	{
		path: '/Disclaimer',
		element: (
			<BasicLayout>
				<DisclaimerView />
			</BasicLayout>
		),
	},
	{
		path: '/FAQs',
		element: (
			<BasicLayout>
				<FAQView />
			</BasicLayout>
		),
	},
	{
		path: '/Privacy_and_Cookies_Policy',
		element: (
			<BasicLayout>
				<PrivacyView />
			</BasicLayout>
		),
	},
	 */
	/* {
		path: '/user/login',
		element: (
			<CentralElementLayout classes={['user-login-page']}>
				<LoginForm />
			</CentralElementLayout>
		),
	},
	{
		path: '/user/logout',
		element: (
			<CentralElementLayout
				classes={['user-logout-page']}
				redirectAfterTo='/user/login'
				timeOutToRedirect={3000}>
				<LogoutForm />
			</CentralElementLayout>
		),
	},
	{
		path: '/user/restricted',
		element: (
			<CentralElementLayout
				classes={['user-restricted-page']}
				redirectAfterTo='/'
				timeOutToRedirect={3000}>
				<ErrorMark />
				<div style={{ textAlign: 'center' }}>Error, al acceder.</div>
			</CentralElementLayout>
		),
	}, */
	{
		path: '/404',
		element: (
			<CenteredLayout>
				<NotFoundView />
			</CenteredLayout>
		),
	},
	{
		path: '*',
		element: (
			<CenteredLayout>
				<NotFoundView />
			</CenteredLayout>
		),
	},
	/* {
		path: '/:alias',
		element: (
			<BasicLayout>
				<InfromationView />
			</BasicLayout>
		),
	}, */
];

export default DefaultRoutes;
